.hoox-before-and-after {
    padding: 48px 0;
}
.hoox-before-and-after__title {
	margin-bottom: 32px;
}

.hoox-before-and-after__items {}

.hoox-before-and-after__items .image-compare {
	padding: 0;
}

.image-compare__label-container {
	padding: 0;
}

.image-compare__label-container-text {
    padding: 6px 8px;
    background: #FCEE9C;
    font-size: 14px !important;
}

.hoox-before-and-after__items-footer-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: var(--hoox-color-sand);

	color: var(--hoox-color-slate-secondary);
	font-size: 18px;
	font-weight: 400;
	line-height: normal;
}

.hoox-before-and-after__btn {
	margin-top: 32px;
}

.swiper-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
	height: 32px;
	margin-top: 32px;
}

.hoox-before-and-after__arrow-prev,
.hoox-before-and-after__arrow-next {
	height: -webkit-fill-available;
}

.hoox-before-and-after__dots {
    position: unset;
    max-width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
	gap: 8px;
	height: fit-content;

	padding: 8px;
	background-color: #FAFAFA;
	border-radius: 32px;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: var(--hoox-color-slate-secondary);
}

.swiper-pagination-bullet {
	opacity: 1;
	background-color: transparent;
	border: 1px solid var(--hoox-color-slate-secondary);
	box-sizing: border-box;
	margin: 0 !important;
}

@media (min-width: 768px) {
	.hoox-before-and-after__items .swiper-wrapper {
		transform: inherit;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 6.6%;
	}
	.hoox-before-and-after__items .swiper-navigation {
		display: none;
	}

	.hoox-before-and-after {
		padding: 80px 0 64px;
	}

	.hoox-before-and-after__title {
		margin-bottom: 48px;
	}

	.image-compare__label-container-text {
		padding: 10px 12px;
		font-size: 18px !important;
	}

	.hoox-before-and-after__btn {
		margin: auto;
		margin-top: 48px;
		max-width: 320px !important;
		min-width: unset;
	}
}

@media (max-width: 768px) {
	.hoox-before-and-after__title {
		max-width: 270px;
		margin-inline: auto;
	}
}